import React from "react";
import { Navigation } from "./navigation";

export const WhyUsPage = (props) => {
  const data = props.data;

  return (
    <div id="why-us-page">
      <Navigation />
      {/* Our USPs Section */}
      <div className="our-usps">
        <div className="section-title text-center">
          <h2>{data ? data.usps.title : "Loading..."}</h2>
          <p className="our-usps-p" >{data ? data.usps.description : "Loading..."}</p>
        </div>
      </div>

      {/* Competition Benchmarking Grid
      <div className="container competition-benchmarking">
        <h2>{data ? data.competitionBenchmarking.title : "Loading..."}</h2>
        <p>{data ? data.competitionBenchmarking.description : "Loading..."}</p>
        <div className="benchmark-grid">
          {data && data.competitionBenchmarking.grid
            ? data.competitionBenchmarking.grid.map((row, index) => (
              <div className="grid-row" key={index}>
                {row.map((item, i) => (
                  <div className="grid-item" key={i}>
                    <p>{item}</p>
                  </div>
                ))}
              </div>
            ))
            : "Loading..."}
        </div>
      </div> */}

      {/* Security/Compliance Logos Section */}
      <div className="container security-logos">
        <h2>{data ? data.securityLogos.title : "Loading..."}</h2>
        <div className="logos">
          {data && data.securityLogos.items
            ? data.securityLogos.items.map((logo, index) => (
              <img key={index} src={logo.src} alt={logo.alt} />
            ))
            : "Loading..."}
        </div>
      </div>

      {/* Testimonials Section */}
      <div className="container testimonials">
        <h2>{data ? data.testimonials.title : "Loading..."}</h2>
        <div className="row">
          {data && data.testimonials.reviews
            ? data.testimonials.reviews.map((review, index) => (
              <div className="col-md-6 testimonial-tile" key={index}>
                <div className="testimonial-content">
                  <img src={review.image} alt={review.author} className="testimonial-img" />
                  <blockquote>
                    <p>"{review.text}"</p>
                    <footer>- {review.author}</footer>
                  </blockquote>
                </div>
              </div>
            ))
            : "Loading..."}
        </div>
      </div>
    </div>
  );
};
