import React, { useState, useEffect, useRef } from "react";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";
import { Navigation } from "../../components/navigation";
import { Helmet } from 'react-helmet-async';
import "react-toastify/dist/ReactToastify.css";
import "./LiveDemo.css";

export const LiveDemo  = ({agent_uuid}) => {
  // Base URLS
  const API_BASE_URL = "https://backend.stage.vozzo.ai/";
  //const API_BASE_URL = 'http://localhost:8002/';
  const API_ENDPOINT = {
    vozzo_agent_session: "vozzo-agent-session",
    list_agent: "website/list-agent",
    upload_audio: "call/upload_audio/",
  };

  /* START: Page Onload Default Form integration-----------------------------------------*/
  const [isPopupVisible, setPopupVisible] = useState(false); // Manage popup visibility
  const [formData, setFormData] = useState({ name: "", companyName: "", mobileNumber: "" });
  const [isLoading, setLoading] = useState(false);
  const [isConnected, setConnected] = useState(false);
  const peerConnectionRef = useRef(null); // Reference to the peer connection
  const mediaStreamRef = useRef(null); // Reference to the local media stream
  const audioElRef = useRef(null); // Reference for the audio element
  const dataChannelRef = useRef(null);
  const endCallTimeoutRef = useRef(null);
  const recordedChunksRefUser = useRef([]);
  const recordedChunksRefAgent = useRef([]);
  const combinedStreamRef = useRef(null);
  const startTimeRef = useRef(null);
  const [convFormData, setConvFormData] = useState({ language: "English", voice: "Shimmer", accent: "Indian", agent_prompt: "" });
  const formRef = useRef(null);

  useEffect(() => {
    setPopupVisible(true); // Show the popup form on page load
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const data = {
      name: formData.get('name'),
      companyName: formData.get('companyName'),
      mobileNumber: formData.get('mobileNumber'),
    };
    setLoading(true);
    // Run fetch request in the background
    submitFormData(new FormData(formRef.current));
    await new Promise(resolve => setTimeout(resolve, 2000));
    setPopupVisible(false);
    setLoading(false);
  };

  const submitFormData = async (formData) => {
    const url = 'https://script.google.com/macros/s/AKfycby2dMu3puFRJOcA9Ep_3VB6q0Ss8QKdItjYQr6XAOKgqGL8e0A7EOLQ3UTDJORgVQneaw/exec';
    try {
      const response = await axios.post(url, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      if (response.status !== 200) {
        throw new Error('Network response was not ok');
      }

      const result = response.data;
      console.log(result);
      //toast.success("Thanks! You’re good to go.", { autoClose: 2000 });
      setPopupVisible(false); // Close the popup after successful submission
    } catch (error) {
      console.log("Error while submitting to Google Sheet:", error);
      //toast.error("Please try again.", { autoClose: 2000 });
    } finally {
      setLoading(false);
    }
  };

  /* START: List Agents API-----------------------------------------*/
  useEffect(() => {
    getAgentListAPI();
  }, []);

  const [agentList, setAgentList] = useState();
  const getAgentListAPI = () => {
    try {
      axios
        .get(`${API_BASE_URL}${API_ENDPOINT.list_agent}`)
        .then((response) => {
          if (response?.data?.success) {
            setAgentList(response.data.data.agents);
          }
        })
        .catch((error) => {
          // toast.error("Failed to load Agent List.");
        });
    } catch (error) {
      // toast.error("Something went wrong, please try again!");
    }
  };

  /* END: List Agents API-----------------------------------------*/

  const formBaseData = {
    language: {
      field_label: "Language",
      data: [
        { label: "English", value: "English" },
        { label: "Hindi", value: "Hindi" },
        { label: "Bengali", value: "Bengali" },
        { label: "Tamil", value: "Tamil" },
        { label: "Telugu", value: "Telugu" },
        { label: "Kannada", value: "Kannada" },
        { label: "Gujarati", value: "Gujarati" },
        { label: "Punjabi", value: "Punjabi" },
        { label: "Marathi", value: "Marathi" },
        { label: "Malayalam", value: "Malayalam" },
        { label: "Assamese", value: "Assamese" },
        { label: "Spanish", value: "Spanish" },
        { label: "Dutch", value: "Dutch" },
        { label: "German", value: "German" },
        { label: "Chinese", value: "Chinese" },
        { label: "French", value: "French" },
        { label: "Japanese", value: "Japanese" },
        { label: "Polish", value: "Polish" },
        { label: "Arabic", value: "Arabic" },
        { label: "Italian", value: "Italian" },
        { label: "Russian", value: "Russian" },
        { label: "Norwegian", value: "Norwegian" },
        { label: "Swedish", value: "Swedish" },
        { label: "Welsh", value: "Welsh" },
        { label: "Ukrainian", value: "Ukrainian" },
        { label: "Greek", value: "Greek" },
        { label: "Irish", value: "Irish" },
        { label: "Latvian", value: "Latvian" },
        { label: "Lithuanian", value: "Lithuanian" },
        { label: "Luxembourgish", value: "Luxembourgish" },
        { label: "Portuguese", value: "Portuguese" },
        { label: "Czech", value: "Czech" },
      ],
    },
    voice: {
      field_label: "Voice",
      data: [
        {
          value: "Alloy",
          label: "Aadhya",
          inspiration: "Goddess Durga, the primordial force",
          gender: "Female",
        },
        {
          value: "Ash",
          label: "Agni",
          inspiration: "Vedic God of Fire",
          gender: "Male",
        },
        {
          value: "Ballad",
          label: "Narayan",
          inspiration: "Lord Vishnu, sustainer of life",
          gender: "Male",
        },
        {
          value: "Coral",
          label: "Tara",
          inspiration: "Goddess Tara, symbolizing a star",
          gender: "Female",
        },
        {
          value: "Echo",
          label: "Shankh",
          inspiration: "Sacred conch, sound of divinity",
          gender: "Male",
        },
        {
          value: "Sage",
          label: "Saras",
          inspiration: "Goddess Saraswati, wisdom and purity",
          gender: "Female",
        },
        {
          value: "Shimmer",
          label: "Meera",
          inspiration: "Devotee of Lord Krishna, grace",
          gender: "Female",
        },
        {
          value: "Verse",
          label: "Vedant",
          inspiration: "Derived from the Vedas, ultimate knowledge",
          gender: "Male",
        },
      ],
    },
    accent: {
      field_label: "Accent",
      data: [
        { label: "Indian", value: "Indian" },
        { label: "American", value: "American" },
        { label: "British", value: "British" },
        { label: "Australian", value: "Australian" },
        { label: "Canadian", value: "Canadian" },
        { label: "Irish", value: "Irish" },
        { label: "German", value: "German" },
        { label: "Italian", value: "Italian" },
        { label: "Spanish", value: "Spanish" },
        { label: "Dutch", value: "Dutch" },
        { label: "Arabic", value: "Arabic" },
        { label: "South African", value: "South African" },
      ],
    },
  };

  const handleConvInputChange = (key, value) => {
    setConvFormData({ ...convFormData, [key]: value });
  };

  // final submit to start conversation
  const handleConversationSubmit = (e) => {
    e.preventDefault();
    startConversation();
  };

  // Request microphone permission
  const requestPermission = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      stream.getTracks().forEach((track) => track.stop()); // Stop tracks immediately after permission check
      return true; // Permission granted
    } catch (error) {
      if (error.name === "NotAllowedError") {
        toast.error("Microphone access denied. Please enable permissions.");
      } else if (error.name === "NotFoundError") {
        toast.error("No microphone found. Please connect a microphone.");
      } else {
        toast.error("Error while requesting microphone permissions.");
      }
      // console.error("Permission error:", error);
      return false; // Permission denied
    }
  };

  // Request to play audio, which triggers sound output permission
  const requestAudioOutputPermission = async () => {
    try {
      const audioEl = document.createElement("audio");
      audioElRef.current = audioEl; // Save the reference for later use
      audioEl.autoplay = true;

      // Try to play audio to trigger sound settings
      const playPromise = audioEl.play();
      if (playPromise !== undefined) {
        playPromise
          .then(() => {
            // console.log("Audio is playing successfully.");
          })
          .catch((error) => {
            // Autoplay blocked
            toast.error("Autoplay audio is blocked. Please interact with the page to enable sound.");
          });
      }

      return true; // Permission to play sound triggered
    } catch (error) {
      toast.error("Error while requesting audio output permissions.");
      // console.error("Audio permission error:", error);
      return false;
    }
  };

  const startConversation = async () => {
    if (!(await requestPermission())) {
      return; // Exit if microphone permissions are not granted
    }
    setLoading(true);
    if (!(await requestAudioOutputPermission())) {
      return; // Exit if audio output permissions are not granted
    }

    try {
      // Step 1: Fetch an ephemeral key from the server
            const EPHEMERAL_KEY = await fetchEphemeralKey();
      
            // Step 2: Create a WebRTC peer connection
            const pc = new RTCPeerConnection();
            peerConnectionRef.current = pc;
      
            // Save the start time of the conversation
            startTimeRef.current = new Date().toISOString();
      
            // Step 3: Set up to play remote audio from the model
            const audioEl = document.createElement("audio");
            audioEl.autoplay = true;
            audioElRef.current = audioEl; // Save the reference for playing audio
      
            // Step 4: Add local audio track for microphone input
            combinedStreamRef.current = new MediaStream();
            mediaStreamRef.current = new MediaStream();
            const localStream = await navigator.mediaDevices.getUserMedia({ audio: true });
            localStream.getTracks().forEach(track => pc.addTrack(track, localStream));
            //localStream.getAudioTracks().forEach(track => mediaStreamRef.current.addTrack(track));
            //mediaStreamRef.current = localStream;
      
            // Step 4.1: Create a combined stream to record the conversation
            pc.ontrack = (event) => {
              const remoteStream = event.streams[0]; // Get the remote stream
              remoteStream.getTracks().forEach((track) => {
                  combinedStreamRef.current.addTrack(track); // Add to the combined stream
                  localStream.getAudioTracks().forEach(track => mediaStreamRef.current.addTrack(track));
              });
              audioEl.srcObject = remoteStream; // Play the audio
              ////console.log("Remote track added to combined stream:", track);
              //console.log("pc.ontrack: Added remote track to combined stream:", remoteStream);
              //console.log("Tracks in combined stream:", combinedStreamRef.current.getTracks());
              };
            
            // Step 5: Create an Session Description Protocol (SDP) offer and set it as the local description
            const offer = await pc.createOffer();
            await pc.setLocalDescription(offer);
      
            // Step 6: Send the SDP offer and metadata to the OpenAI Realtime API
            const baseUrl = "https://api.openai.com/v1/realtime";
            const model = "gpt-4o-realtime-preview-2024-12-17";
            const sdpResponse = await fetch(`${baseUrl}?model=${model}`, {
              method: "POST",
              body: offer.sdp, // Send the SDP offer as plain text
              headers: {
                Authorization: `Bearer ${EPHEMERAL_KEY}`,
                "Content-Type": "application/sdp",
              },
            });
            if (!sdpResponse.ok) {
              const errorResponse = await sdpResponse.json();
              // //console.error("Error:", errorResponse);
              throw new Error("Failed to fetch SDP answer");
            }
      
            // Step 7: Set the remote description using the SDP answer from the server
            const answer = await sdpResponse.text();
            await pc.setRemoteDescription({
              type: "answer",
              sdp: answer,
            });
            setConnected(true); // Mark as connected
      
            // Step 8: Create a data channel from a peer connection
            //console.log("creating data channel:", pc);
            const dc = pc.createDataChannel("oai-events");
            //console.log("data channel created:");
            // Save the data channel reference
            setupDataChannel(dc);
            
            // Step 9: Save the data channel reference
            pc.onconnectionstatechange = () => {
              if (pc.connectionState === "connected") {
                  startRecording(combinedStreamRef.current, mediaStreamRef.current);
                  //console.log("Call recording started with both streams");
                  }
              };
      
            // Step 10: Set a timeout to end the call after 3 minutes (180,000 milliseconds)
            endCallTimeoutRef.current = setTimeout(() => {
              //console.log("Ending call after 3 minutes");
              toast.info("2 minutes limit reached.");
              endConnection();
            }, 120000);
      
            } catch (error) {
              //console.log("error to start the conversation:", error);
              toast.error("Please try again.");
            } finally {
              setLoading(false);
            }
          };
      
  // Fetch ephemeral key
  const fetchEphemeralKey = async () => {
    
    const metadata = {
      language: convFormData.language,
      voice: convFormData.voice?.toLowerCase(),
      gender: formBaseData.voice.data.find((x) => x.value === convFormData.voice)?.gender || "",
      accent: convFormData.accent,
      agent_prompt: convFormData.agent_prompt,
    };
    const response = await fetch(`${API_BASE_URL}${API_ENDPOINT.vozzo_agent_session}`, {
      method: "POST",
      body: JSON.stringify({ ...metadata, ...formData }),
      headers: { "Content-Type": "application/json" },
    });

    if (!response.ok) throw new Error("Failed to fetch ephemeral key");
    const data = await response.json();
    return data?.client_secret?.value;
  };
      
  // Setup data channel
  const setupDataChannel = (dc) => {
    dc.addEventListener("message", (e) => {
      const realtimeEvent = JSON.parse(e.data);
      //console.log("on message from data channel: ", realtimeEvent);
    });

    dc.addEventListener("open", () => {
      const responseCreate = {
        type: "response.create",
        response: {
          modalities: ["text"],
          instructions: "hello, how can i help you?",
        },
      };
      dc.send(JSON.stringify(responseCreate));
    });
  };
      
  // Start recording
  const startRecording = (combinedStream, mediaStream) => {
    combinedStream.getTracks().forEach(track => {
      //console.log(`combinedStream: Track ID: ${track.id}, Kind: ${track.kind}, ReadyState: ${track.readyState}`);
    });
    mediaStream.getTracks().forEach(track => {
      //console.log(`mediaStream: Track ID: ${track.id}, Kind: ${track.kind}, ReadyState: ${track.readyState}`);
    });
    
    const mediaRecorderAgent = new MediaRecorder(combinedStream);
    mediaRecorderAgent.ondataavailable = (event) => {
      //console.log("Data available event fired: agent audio: ", event.data.size);
      if (event.data.size > 0) {
        //console.log("Recording event.data: ", event.data);
        recordedChunksRefAgent.current.push(event.data);
        uploadAudio();
      }
    };
      
    const mediaRecorderUser = new MediaRecorder(mediaStream);
    mediaRecorderUser.ondataavailable = (event) => {
      //console.log("Data available event fired: microphone audio: ", event.data.size);
      if (event.data.size > 0) {
        //console.log("Recording event.data: ", event.data);
        recordedChunksRefUser.current.push(event.data);
        //uploadAudio();
      }
    };

    mediaRecorderAgent.start();
    mediaRecorderUser.start();
  };
  
  // Upload audio
  const uploadAudio = async()=> {
    try{
      // Upload the recorded audio to S3
      const blob_agent = new Blob(recordedChunksRefAgent.current, { type: "audio/webm"});
      const blob_user = new Blob(recordedChunksRefUser.current, { type: "audio/webm"});
      
      //console.log("Recording blob agent:", blob_agent);
      //console.log("Recording blob user:", blob_user);

      const endTime = new Date().toISOString();
      const duration =  Math.floor((Date.parse(endTime) - Date.parse(startTimeRef.current)) / 1000);

      // Create a FormData object and append the file
      const formData = new FormData();
      formData.append('file_agent', blob_agent, 'recording_agent.webm');
      formData.append('file_user', blob_user, 'recording_user.webm');
      formData.append('start_time', startTimeRef.current);
      formData.append('end_time', endTime);
      formData.append('duration', duration.toString());
      formData.append('language', convFormData.language);
      
      const audioUploadResponse = await fetch(`${API_BASE_URL}${API_ENDPOINT.upload_audio}${agent_uuid}`, {
        method: "POST",
        body: formData, // Send the SDP offer as plain text
        headers: {},
      });  

      if (!audioUploadResponse.ok) {
        // throw new Error("Failed to upload audio");
        //console.log("failed uploaded to S3");
      }
      else{
        //console.log("Call recording uploaded to S3");
      }

      // toast.info("Conversation ended.");
      setConnected(false); // Mark as disconnected
    }
    catch (error) {
      //console.error("Error while uploading the audio:", error);
      //toast.error("Failed to upload the audio.");
    }
  };

  // End the connection
  const endConnection = async () => {
    try {
      // Close the peer connection
      //console.log("Ending the connection...");
      if (endCallTimeoutRef.current) {
        clearTimeout(endCallTimeoutRef.current);
        endCallTimeoutRef.current = null;
      }
      if (peerConnectionRef.current) {
        peerConnectionRef.current.close();
        peerConnectionRef.current = null;
      }
      if (dataChannelRef.current) {
        dataChannelRef.current.close();
        dataChannelRef.current = null;
      }
      // Stop all tracks in the media stream
      if (mediaStreamRef.current) {
        mediaStreamRef.current.getTracks().forEach((track) => track.stop());
        mediaStreamRef.current = null;
      }
      if (combinedStreamRef.current) {
        combinedStreamRef.current.getTracks().forEach((track) => track.stop());
        combinedStreamRef.current = null;
      }
      if (recordedChunksRefAgent.current) {
        recordedChunksRefAgent.current = [];
      }
      if (recordedChunksRefUser.current) {
        recordedChunksRefUser.current = [];
      }

      //toast.info("Conversation Ended.");
      setConnected(false); // Mark as disconnected

    } catch (error) {
      //console.error("Error while ending the connection:", error);
      toast.error("Failed to end the conversation.");
    }
  };
        /* END: WebRTC realtime integration------------------------------------------*/
      
  /* END: WebRTC realtime integration------------------------------------------*/
  const togglePopup = () => {
    setPopupVisible(!isPopupVisible);
  };

  return (
    <div id="live-demo">
      <Helmet>
        <title>Live Demo | Experience Vozzo.ai's AI-Powered Voice Bot</title>
        <meta name="description" content="Experience Vozzo.ai's voice bots live demo and discover how our AI-powered solutions enhance communication, streamline workflows, and improve customer experiences in real time." />
      </Helmet>
      <Navigation />

      {isPopupVisible && (
        <div className="popup-overlay">
          <div className="popup-content" style={{ position: 'relative' }}>

            <h3>Experience it—quick details, and you're in!</h3>
            <form ref={formRef} onSubmit={handleFormSubmit}>
              <div className="form-group">
                <label>
                  Name*:
                  <input type="text" name="name" value={formData.name} onChange={handleInputChange} required />
                </label>
              </div>
              <div className="form-group">
                <label>
                  Company Name*:
                  <input type="text" name="companyName" value={formData.companyName} onChange={handleInputChange} required />
                </label>
              </div>
              <div className="form-group">
                <label>
                  Mobile Number*:
                  <input type="tel" name="mobileNumber" value={formData.mobileNumber} onChange={handleInputChange} required />
                </label>
              </div>
              <p style={{ fontSize: '12px', color: '#888', marginTop: '10px' }}>
                Share real info for the best experience.
              </p>
              <button type="submit" className="btn btn-custom popup-btn" disabled={isLoading}>
                {isLoading ? (
                  <div className="listening-animation" >
                    Going Live<span className="dot">.</span><span className="dot">.</span><span className="dot">.</span><span className="dot">.</span>
                  </div>
                ) : (
                  "Go Live"
                )}
              </button>
            </form>
          </div>
        </div>
      )}

      <div className="text-center">
        <div>
          <div className="section-title">
            <h2>The Voice Interface of AI</h2>
            <p>Our flexible AI agents are ready to meet your diverse business needs.</p>
          </div>
          <div className="try-sections">
            <div className="try-now">
              <h1>Voice Agents</h1>
              <div className="agent">
                <img src="/img/live-demo/agent.webp" className="agent-image" alt="agent-image" />
              </div>
              <form onSubmit={handleConversationSubmit}>
                {/* Agent Dropdown */}
                <div className="form-group">
                  <label>Template</label>
                  <select
                    aria-label={`Select Template`}
                    className="input-sm form-control"
                    value={convFormData.agent_prompt}
                    disabled={isLoading || isConnected}
                    onChange={(e) => handleConvInputChange("agent_prompt", e.target.value)}
                  >
                    <option value="">General Agent...</option>
                    {agentList &&
                      agentList.map((optn) => (
                        <option key={optn.agent_id} value={optn.agent_prompt}>
                          {optn.agent_name}
                        </option>
                      ))}
                  </select>
                </div>

                {/* Language, Voice, Accent Dropdowns */}
                {Object.keys(formBaseData).map((key) => (
                  <div className="form-group" key={key}>
                    <label>{formBaseData[key].field_label}</label>
                    <select
                      aria-label={`Select ${key}`}
                      className="input-sm form-control"
                      value={convFormData[key] || ""}
                      disabled={isLoading || isConnected}
                      onChange={(e) => handleConvInputChange(key, e.target.value)}
                      required
                    >
                      <option value="">select...</option>
                      {formBaseData[key].data.map((optn) => (
                        <option key={optn.label} value={optn.value}>
                          {optn.label}
                        </option>
                      ))}
                    </select>
                  </div>
                ))}
                {!isConnected && (
                  <button type="submit" className="btn start-conversation" disabled={isLoading}>
                    {isLoading ? "Connecting..." : "Start Conversation"}
                  </button>
                )}
              </form>

              {isConnected && (
                <>
                  <div className="listening-animation" style={{ marginBottom: '10px', textAlign: 'center' }}>
                          Listening<span className="dot">.</span><span className="dot">.</span><span className="dot">.</span><span className="dot">.</span>
                        </div>
                  <button className="btn end-conversation" onClick={endConnection}>
                    End Conversation
                  </button>
                </>
              )}

            </div>
          </div>
        </div>
      </div>
      <ToastContainer position="top-right" autoClose={2000} />
    </div>
  );
};
