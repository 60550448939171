import React, { useEffect } from "react";
import { Navigation } from "./navigation";
import { Helmet } from 'react-helmet-async';

export const ServicesPage = (props) => {
    const data = props.data;

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: "smooth" });
    }, []);

    return (
        <div id="services">
            <Helmet>
                <title>AI-Powered Voice Bots for Various Indutsry</title>
                <meta name="description" content="Discover Vozzo.ai's tailored AI-powered voice bot solutions for various industries, including healthcare, retail, Financial, and customer service. Revolutionize your communication today." />
            </Helmet>
            <Navigation />
            <div className="text-center services">
                <div>
                    <div className="section-title">
                        <h2>Custom AI Solutions for Every Industry</h2>
                        <p>
                            Our flexible AI agents are ready to meet your diverse business needs.
                        </p>
                    </div>
                    <div className="row">
                        {props.data
                            ? props.data.map((d, i) => (
                                <div key={`${d.name}-${i}`} className="col-xs-6 col-md-3">
                                    {" "}
                                    <i className={d.icon}></i>
                                    <div className="service-desc">
                                        <h3>{d.name}</h3>
                                        <p>{d.text}</p>
                                    </div>
                                </div>
                            ))
                            : "loading"}
                    </div>
                </div>
            </div>
        </div>
    );
};
