import React, { useEffect } from "react";
import { Navigation } from "./navigation";
import { Helmet } from 'react-helmet-async';
import { Link } from "react-router-dom";

export const BlogsPage = (props) => {
    const data = props.data;

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: "smooth" });
    }, []);

    return (
        <div id="blogs-page">
            <Helmet>
                <title>Insights on AI-Powered Voice Bots</title>
                <meta name="description" content="Explore insights on AI-powered voice bots with Vozzo.ai. Learn about their benefits, applications, and how they are transforming industries with smarter communication solutions." />
            </Helmet>
            <Navigation />
            <div className="text-center">
                <div className="section-title">
                    <h2>Our Latest Blogs</h2>
                    <p>Stay up-to-date with the latest news and trends in AI and machine learning.</p>
                </div>
            </div>

            <div id="blogs-section">
                <div className="blogs">
                    <div className="row">

                        <div className="col-lg-4 col-md-6 mb-4 card">
                            <div className="card-header">
                                <Link to="/blog-post-1">
                                    <img src="/img/blog-1.jpg" className="img-fluid" alt="Blog thumbnail" />
                                </Link>
                            </div>
                            <div className="card-body">
                                <div className="card-title">
                                    <Link to="/blog-post-1">
                                        AI Voice Bots Transforming BFSI Industry Globally
                                    </Link>
                                </div>
                                <div className="card-description">
                                    <p>
                                        The Banking, Financial Services, and Insurance (BFSI) industry has always been at the forefront of adopting cutting-edge technologies to...
                                    </p>
                                </div>
                                <div className="author">
                                    <img src="/img/team/divy.jpg" alt="Author" className="avatar shadow" />
                                    <div className="author-name">
                                        <span>Divy Agarwal</span>
                                        <div className="author-stats">
                                            <small>Posted on 11 Jan 2025</small>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6 mb-4 card">
                            <div className="card-header">
                                <Link to="/blog-post-2">
                                    <img src="/img/blog-2.jpg" className="img-fluid" alt="Blog thumbnail" />
                                </Link>
                            </div>
                            <div className="card-body">
                                <div className="card-title">
                                    <Link to="/blog-post-2">
                                        Enhancing Patient Experiences with AI bots
                                    </Link>
                                </div>
                                <div className="card-description">
                                    <p>
                                        In today’s fast-paced world, where convenience and efficiency are paramount, healthcare is undergoing a technological revolution...
                                    </p>
                                </div>
                                <div className="author">
                                    <img src="/img/team/divy.jpg" alt="Author" className="avatar shadow" />
                                    <div className="author-name">
                                        <span>Divy Agarwal</span>
                                        <div className="author-stats">
                                            <small>Posted on 08 Jan 2025</small>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* <div className="col-lg-4 col-md-6 mb-4 card">
                            <div className="card-header">
                                <Link to="/blog-post-1">
                                    <img src="/img/blog-3.jpg" className="img-fluid" alt="Blog thumbnail" />
                                </Link>
                            </div>
                            <div className="card-body">
                                <div className="card-title">
                                    <Link to="/blog-post-1">
                                        AI Voice Bots Transforming BFSI Industry Globally
                                    </Link>
                                </div>
                                <div className="card-description">
                                    <p>
                                        AI voice bots are transforming the BFSI sector by enhancing customer interactions with proven ROI. Vozzo leads the way...
                                    </p>
                                </div>
                                <div className="author">
                                    <img src="/img/team/divy.jpg" alt="Author" className="avatar shadow" />
                                    <div className="author-name">
                                        <span>Divy Agarwal</span>
                                        <div className="author-stats">
                                            <small>Posted on 05 Jan 2025</small>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> */}

                    </div>
                </div>
            </div>
        </div>
    );
};
