import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";

// Function to handle smooth scrolling with an offset
const scrollToSection = (id) => {
  const section = document.getElementById(id);
  if (section) {
    const headerOffset = 83;
    const elementPosition = section.getBoundingClientRect().top;
    const offsetPosition = elementPosition + window.pageYOffset - headerOffset;

    window.scrollTo({
      top: offsetPosition,
      behavior: "smooth",
    });
  }
};

const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: "smooth",
  });
};

export const Navigation = (props) => {
  const location = useLocation();
  const [activeLink, setActiveLink] = useState(location.pathname);

  useEffect(() => {
    setActiveLink(location.pathname);
  }, [location.pathname]);

  const handleSetActive = (path, sectionId) => {
    setActiveLink(path);
    if (sectionId) {
      scrollToSection(sectionId);
    } else if (path === "/") {
      scrollToTop();
    }
  };

  return (
    <nav id="menu" className="navbar navbar-default navbar-fixed-top">
      <div className="container">
        <div className="navbar-header">
          <button type="button" className="navbar-toggle collapsed" data-toggle="collapse" data-target="#bs-example-navbar-collapse-1">
            <span className="sr-only">Toggle navigation</span>
            <span className="icon-bar"></span>
            <span className="icon-bar"></span>
            <span className="icon-bar"></span>
          </button>
          <Link to="/" onClick={() => handleSetActive("/")}>
            <img src={`${process.env.PUBLIC_URL}/img/vozzo-logo.jpg`} width={100} alt="Vozzo.ai" />
          </Link>
        </div>
        <div className="collapse navbar-collapse" id="bs-example-navbar-collapse-1">
          <ul className="nav navbar-nav navbar-right">
            <li className={activeLink === "/" ? "active" : ""}>
              <Link to="/" onClick={() => handleSetActive("/")}>
                Home
              </Link>
            </li>
            <li className={activeLink === "/about" ? "active" : ""}>
              <Link to="/about" onClick={() => handleSetActive("/about")}>
                About
              </Link>
            </li>
            <li className={activeLink === "/services" ? "active" : ""}>
              <Link to="/services" onClick={() => handleSetActive("/services")}>
                Services
              </Link>
            </li>
            <li className={activeLink === "/live-demo" ? "active" : ""}>
              <Link to="/live-demo" onClick={() => handleSetActive("/live-demo")}>
                Live Demo
              </Link>
            </li>
            <li className={activeLink === "/contact" ? "active" : ""}>
              <Link to="/contact" onClick={() => handleSetActive("/contact")}>
                Contact
              </Link>
            </li>
            <li className={activeLink === "/blogs" ? "active" : ""}>
              <Link to="/blogs" onClick={() => handleSetActive("/blogs")}>
                Blogs
              </Link>
            </li>
            <Link to="/contact" className="btn btn-custom btn-lg page-scroll" onClick={() => handleSetActive("/contact")}>
              Schedule demo
            </Link>
          </ul>
        </div>
      </div>
    </nav>
  );
};
