import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";

// Function to handle smooth scrolling with an offset
const scrollToSection = (id) => {
  const section = document.getElementById(id);
  if (section) {
    const headerOffset = 83;
    const elementPosition = section.getBoundingClientRect().top;
    const offsetPosition = elementPosition + window.pageYOffset - headerOffset;

    window.scrollTo({
      top: offsetPosition,
      behavior: "smooth",
    });
  }
};

const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: "smooth",
  });
};

export const Header = (props) => {
  const location = useLocation();
  const [activeLink, setActiveLink] = useState(location.pathname);

  useEffect(() => {
    setActiveLink(location.pathname);
  }, [location.pathname]);

  const handleSetActive = (path, sectionId) => {
    setActiveLink(path);
    if (sectionId) {
      scrollToSection(sectionId);
    } else if (path === "/") {
      scrollToTop();
    }
  };

  return (
    <header id="header">
      <div className="intro">
        <div className="overlay">
          <div className="container">
            <div className="row intro-content">
              {/* First Column */}
              <div className="col-md-6 intro-text">
                <h1>
                  <span>{props.data ? props.data.title1 : "Loading"}</span>
                  <br />
                  <span>{props.data ? props.data.title2 : "Loading"}</span>
                </h1>
                <div>
                  <p>{props.data ? props.data.paragraph : "Loading"}</p>
                </div>
                 {/* <div className="header-cta" to="/live-demo">*/}
                <Link to="/live-demo" className="btn btn-custom btn-lg page-scroll header-cta" onClick={() => handleSetActive("/live-demo")}>
                  Experience the Magic
                </Link>
                {/*</div> */}
              </div>
              {/* Second Column */}
              <div className="col-md-6 intro-image">
                <img src={`${process.env.PUBLIC_URL}/img/header-image.png`} alt="Header" />
              </div>
              {/* Wave Pattern */}
              <div className="wave-container">
                <svg
                  className="waves"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlnsXlink="http://www.w3.org/1999/xlink"
                  viewBox="0 24 150 40"
                  preserveAspectRatio="none"
                  shape-rendering="auto"
                >
                  <defs>
                    <path
                      id="gentle-wave"
                      d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z"
                    ></path>
                  </defs>
                  <g className="moving-waves">
                    <use xlinkHref="#gentle-wave" x="48" y="-1" fill="rgb(38, 154, 216)" />
                    <use xlinkHref="#gentle-wave" x="48" y="0" fill="rgb(50, 160, 218)" />
                    <use xlinkHref="#gentle-wave" x="48" y="1" fill="rgb(63, 167, 221)" />
                    <use xlinkHref="#gentle-wave" x="48" y="2" fill="rgb(75, 173, 223)" />
                    <use xlinkHref="#gentle-wave" x="48" y="3" fill="rgb(88, 179, 225)" />
                    <use xlinkHref="#gentle-wave" x="48" y="4" fill="rgb(100, 185, 228)" />
                    <use xlinkHref="#gentle-wave" x="48" y="5" fill="rgb(113, 191, 230)" />
                    <use xlinkHref="#gentle-wave" x="48" y="6" fill="rgb(125, 197, 232)" />
                    <use xlinkHref="#gentle-wave" x="48" y="7" fill="rgb(138, 203, 235)" />
                    <use xlinkHref="#gentle-wave" x="48" y="8" fill="rgb(150, 209, 237)" />
                    <use xlinkHref="#gentle-wave" x="48" y="9" fill="rgb(163, 215, 239)" />
                    <use xlinkHref="#gentle-wave" x="48" y="10" fill="rgb(175, 221, 242)" />
                    <use xlinkHref="#gentle-wave" x="48" y="11" fill="rgb(188, 227, 244)" />
                    <use xlinkHref="#gentle-wave" x="48" y="12" fill="rgb(200, 233, 246)" />
                    <use xlinkHref="#gentle-wave" x="48" y="13" fill="rgb(213, 239, 249)" />
                    <use xlinkHref="#gentle-wave" x="48" y="14" fill="rgb(225, 245, 251)" />
                    <use xlinkHref="#gentle-wave" x="48" y="15" fill="rgb(238, 251, 253)" />
                    <use xlinkHref="#gentle-wave" x="48" y="16" fill="rgb(250, 255, 255)" />
                    <use xlinkHref="#gentle-wave" x="48" y="17" fill="rgb(255, 255, 255)" />
                  </g>
                </svg>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};
