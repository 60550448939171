import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
import { Navigation } from "./components/navigation";
import { Header } from "./components/header";
import { Features } from "./components/features";
import { LiveDemo } from "./pages/LiveDemo/LiveDemo";
import { AboutPage } from "./components/AboutPage";
import { ServicesPage } from "./components/ServicesPage";
import { ContactPage } from "./components/ContactPage";
import { BlogsPage } from "./components/BlogsPage";
import { BlogPost1 } from "./components/BlogPost1";
import { BlogPost2 } from "./components/BlogPost2";
import { Footer } from "./components/footer";
import { WhyUsPage } from "./components/WhyUsPage";
import JsonData from "./data/data.json";
//import VozzoAgentLiveWeb from './pages/LiveDemo/VozzoAgentLiveWeb';
import SmoothScroll from "smooth-scroll";
import "./App.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
// import { About } from "./components/about";
// import { Services } from "./components/services";
// import { Gallery } from "./components/gallery";
// import { Testimonials } from "./components/testimonials";
// import { Team } from "./components/Team";
// import { Contact } from "./components/contact";

export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
});

const App = () => {
  const [landingPageData, setLandingPageData] = useState({});

  useEffect(() => {
    setLandingPageData(JsonData);
  }, []);

  return (
    <HelmetProvider>
      <Router>
        <Routes>
          {/* Route for the homepage */}
          <Route
            path="/"
            element={
              <>
                <Navigation />
                <Header data={landingPageData.Header} />
                <Features data={landingPageData.Features} />
                <Footer data={landingPageData.Contact} />
                {/* Uncomment these sections as needed */}
                {/* <About data={landingPageData.About} /> */}
                {/* <Services data={landingPageData.Services} /> */}
                {/* <Gallery data={landingPageData.Gallery} />
              <Testimonials data={landingPageData.Testimonials} />
              <Team data={landingPageData.Team} /> */}
                {/* <Contact data={landingPageData.Contact} /> */}
              </>
            }
          />
          {/* Route for the About page */}
          <Route path="/about" element={
            <>
              <AboutPage data={landingPageData.aboutPage} />
              < Footer />
            </>
          } />
          {/* Route for the Services page */}
          <Route path="/services" element={
            <>
              <ServicesPage data={landingPageData.Services} />
              < Footer />
            </>
          } />
          {/* Route for the Live Demo page */}
          <Route path="/live-demo" element={
            <>
              <LiveDemo agent_uuid="ubzIxSP3xNBTzeYxZy"/>
              < Footer />
            </>
          } />
          {/* Route for the Contact page */}
          <Route path="/contact" element={<ContactPage data={landingPageData.Contact} />} />

          {/* Route for the Blogs page */}
          <Route path="/blogs" element={
            <>
              <BlogsPage data={landingPageData.blogsPage} />
              < Footer />
            </>

          } />
          {/* Route for the Blog post-1 */}
          <Route path="/blog-post-1" element={<BlogPost1 data={landingPageData.blogPost1} />} />

          {/* Route for the Blog post-2 */}
          <Route path="/blog-post-2" element={<BlogPost2 data={landingPageData.blogPost2} />} />

          {/* Route for the Why Us page */}
          <Route path="/why-us" element={<WhyUsPage data={landingPageData.whyUsPage} />} />


        </Routes>
      </Router >
    </HelmetProvider>

  );
};

export default App;
